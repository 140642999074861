import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Import useAuth hook
import './Login.css'; // Optional: Create and style this CSS file for better UI

export default function Login() {
  const [emailId, setEmailId] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login } = useAuth(); // Get login function from useAuth hook
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = {
      email_id: emailId,
      passwords: password,
    };

    fetch('https://alibizness.com/shop/login.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          login(emailId); // Call login function with emailId from AuthContext
          navigate('/confirm');
        } else {
          setError('Invalid email or password. Please try again.');
        }
      })
      .catch(error => {
        console.error('There was an error submitting the form:', error);
        setError('An error occurred. Please try again later.');
      });
  };

  return (
    <div className="Login">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Email:
          <input
            type="email"
            value={emailId}
            onChange={(e) => setEmailId(e.target.value)}
            required
            autoComplete="email"
            className="input-field"
          />
        </label>
        <br />
        <label>
          Password:
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            autoComplete="current-password"
            className="input-field"
          />
        </label>
        <br />
        {error && <p className="error-message">{error}</p>}
        <button type="submit" className="submit-button">Login</button>
      </form>
      <p className="signup-prompt">
        If you don't have an account, <Link to="/signup">sign up here</Link>.
      </p>
    </div>
  );
}
