import React, { useEffect, useState } from 'react';
import SlickSlider from 'react-slick';
import axios from 'axios';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"; 
import './Slider.css';

const Slider = () => {
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get('https://alibizness.com/shop/display_slider.php');
        setSlides(response.data); // Assuming the API returns an array of image URLs
      } catch (error) {
        console.error('Error fetching the slider data', error);
      }
    };

    fetchData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="slider-container">
      <SlickSlider {...settings}>
        {slides.map((slide, index) => (
          <div key={index}>
            <img src={`https://alibizness.com/shop/${slide.image_url}`} alt={`Slide ${index + 1}`} className="slider-image" />
                     
          </div>
        ))}
      </SlickSlider>
    </div>
  );
};

export default Slider;
