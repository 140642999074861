import React, { useState } from 'react';
import axios from 'axios';
import './Tracking.css';

const getStatusTextAndColor = (statusId) => {
    switch (parseInt(statusId, 10)) {
        case 1:
            return { text: 'Order Accepted', color: 'green' };
        case 2:
            return { text: 'Order In Progress', color: 'yellow' };
        case 3:
            return { text: 'Order Rejected', color: 'red' };
        case 4:
            return { text: 'Order Delivered', color: 'purple' };
        default:
            return { text: 'Unknown Status', color: 'gray' };
    }
};

const Tracking = () => {
  const [trackingNo, setTrackingNo] = useState('');
  const [trackingData, setTrackingData] = useState(null);
  const [error, setError] = useState('');

  const handleSearch = async () => {
    try {
      const response = await axios.get(`https://alibizness.com/shop/get_tracking_info.php?tracking_no=${trackingNo}`);
      if (response.data.error) {
        setError(response.data.error);
        setTrackingData(null);
      } else {
        setTrackingData(response.data);
        setError('');
      }
    } catch (err) {
      setError('Error fetching tracking information.');
      setTrackingData(null);
    }
  };

  return (
    <div className="tracking-container">
      <h1>Track Your Order</h1>
      <input
        type="text"
        value={trackingNo}
        onChange={(e) => setTrackingNo(e.target.value)}
        placeholder="Enter Tracking Number"
        className="tracking-input"
      />
      <button onClick={handleSearch} className="tracking-button">Search</button>
      {error && <p className="tracking-error">{error}</p>}
      {trackingData && (
        <div className="tracking-results">
          <h2>Order Details</h2>
          <table className="order-master-table">
            <tbody>
              <tr>
                <th>Tracking No</th>
                <td>{trackingData.product_master.order_master_id}</td>
              </tr>
              <tr>
                <th>Order Date</th>
                <td>{trackingData.product_master.order_date}</td>
              </tr>
              <tr>
                <th>Product Amount</th>
                <td>{trackingData.product_master.order_amount}</td>
              </tr>
              <tr>
                <th>Order Status</th>
                <td style={{ color: getStatusTextAndColor(trackingData.product_master.order_status_id).color }}>
                  {getStatusTextAndColor(trackingData.product_master.order_status_id).text}
                </td>
              </tr>
            </tbody>
          </table>
          <h3>Product Details</h3>
          <table className="order-details-table">
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {trackingData.product_details.map(detail => (
                <tr key={detail.detail_id}>
                  <td>{detail.item_name}</td>
                  <td>{detail.qty}</td>
                  <td>{detail.price}</td>
                  <td>{detail.amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Tracking;
