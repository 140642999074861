import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './Latest.css';
import { CartContext } from './CartContext';

const Latest = () => {
  const [products, setProducts] = useState([]);
  const { addToCart } = useContext(CartContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetch('https://alibizness.com/shop/get_latest_product.php')
      .then(response => response.json())
      .then(data => setProducts(data))
      .catch(error => console.error('Error fetching products:', error));
  }, []);

  const handleAddToCart = (product) => {
    const defaultQuantity = 1;
    addToCart(product, defaultQuantity);
  };

  const handleProductClick = (id) => {
    navigate(`/productdetail/${id}`);
  };

  return (
    <div className="latest-container">
      <h2 className="latest-title">Our Latest Products</h2>
      <div className="products-container">
        {products.map(product => (
          <div 
            key={product.item_id} 
            className="product-card" 
            onClick={() => handleProductClick(product.item_id)}
          >
            <img 
              src={`https://alibizness.com/shop/uploads/${product.item_picture}`} 
              alt={product.item_name} 
              className="product-image" 
            />
            <h3>{product.item_name}</h3>
            <p>Rs {product.item_price}</p>
           
          </div>
        ))}
      </div>
    </div>
  );
};

export default Latest;
