import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Shop.css';

const Shop = () => {
  const [items, setItems] = useState([]);
  const [error, setError] = useState('');
  const [search, setSearch] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [priceRange, setPriceRange] = useState({ min: '', max: '' });

  const navigate = useNavigate();

  useEffect(() => {
    fetchItems();
    fetchCategories();
  }, []);

  const fetchItems = async () => {
    try {
      const response = await axios.get('https://alibizness.com/shop/get_all_items.php');
      setItems(response.data);
    } catch (err) {
      setError('Error fetching items.');
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get('https://alibizness.com/shop/get_category.php');
      setCategories(response.data);
    } catch (err) {
      setError('Error fetching categories.');
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategories(prev => {
      if (prev.includes(category)) {
        return prev.filter(c => c !== category);
      } else {
        return [...prev, category];
      }
    });
  };

  const handlePriceRangeChange = (e) => {
    const { name, value } = e.target;
    setPriceRange(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const filterItems = () => {
    return items.filter(item => {
      const inCategory = selectedCategories.length === 0 || selectedCategories.includes(item.category_name);
      const inPriceRange = (!priceRange.min || item.item_price >= priceRange.min) && (!priceRange.max || item.item_price <= priceRange.max);
      const inSearch = item.item_name.toLowerCase().includes(search.toLowerCase());
      return inCategory && inPriceRange && inSearch;
    });
  };

  const handleSelectProduct = (itemId) => {
  
    navigate(`/productdetail/${itemId}`);
  };

  return (
    <div className="shop-container">
      <div className="shop-header">
        <h2>Shop</h2>
        <div className="search-bar">
          <input 
            type="text" 
            className='form-control' 
            placeholder="Search for items..." 
            value={search} 
            onChange={handleSearch} 
          />
        </div>
      </div>
      {error && <p className="text-danger">{error}</p>}
      <div className="shop-content">
        <div className="filters-container">
          <div className="category-filter">
            <h3>Categories</h3>
            {categories.map(category => (
              <div key={category.category_id} className="category-item">
                <span>{category.category_name}</span>
                <input
                  type="checkbox"
                  id={category.category_name}
                  name={category.category_name}
                  checked={selectedCategories.includes(category.category_name)}
                  onChange={() => handleCategoryChange(category.category_name)}
                />
              </div>
            ))}
          </div>
          <div className="price-filter">
            <h3>Price Range</h3>
            <input
              type="number"
              name="min"
              placeholder="Min"
              value={priceRange.min}
              onChange={handlePriceRangeChange}
            />
            <input
              type="number"
              name="max"
              placeholder="Max"
              value={priceRange.max}
              onChange={handlePriceRangeChange}
            />
          </div>
        </div>
        <div className="items-container">
          {filterItems().map(item => (
            <div 
              key={item.item_id} 
              className="item-card" 
              onClick={() => handleSelectProduct(item.item_id)} // Ensure this calls handleSelectProduct
            >
              <img 
                src={`https://alibizness.com/shop/uploads/${item.item_picture}`} 
                alt={item.item_name} 
                className="product-image" 
              />
              <h3>{item.item_name}</h3>
              <h4>{item.category_name}</h4>
              <p>Rs {item.item_price}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Shop;
