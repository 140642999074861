import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CartContext } from './CartContext';
import { useAuth } from './AuthContext'; // Import useAuth to get user details
import './Confirm.css';

const Confirm = () => {
  const { cartItems, clearCart, totalQuantity, calculateTotalAmount } = useContext(CartContext);
  const { user } = useAuth(); // Get the user from AuthContext
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [orderNo, setOrderNo] = useState(null); // State to store the order number
  const [orderConfirmed, setOrderConfirmed] = useState(false); // State to track order confirmation

  useEffect(() => {
    if (user) {
      // Fetch user details using the emailId stored in user
      fetch(`https://alibizness.com/shop/get_user.php?email_id=${user.emailId}`)
        .then(response => response.json())
        .then(data => setUserInfo(data))
        .catch(error => console.error('Error fetching user details:', error));
    }
  }, [user]);

  const handleContinueShopping = () => {
    navigate('/');
  };

  const handleConfirmOrder = () => {
    const orderData = {
      email_id: user.emailId,
      order_amount: calculateTotalAmount(),
      cart_items: cartItems,
      payment_method: paymentMethod,
    };

    fetch('https://alibizness.com/shop/insert_order.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(orderData),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setOrderNo(data.order_no); // Set the received order number
          setOrderConfirmed(true); // Set order confirmed flag
          clearCart(); // Clear the cart
          // navigate('/confirm'); // You may redirect here if needed
        } else {
          console.error('Error confirming order:', data.message);
        }
      })
      .catch(error => console.error('Error confirming order:', error));
  };

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  return (
    <div className="cart-page">
      {!orderConfirmed ? (
        <>
          {userInfo && (
            <div className="user-info">
              <h3>User Details</h3>
              <p>Country Name: {userInfo.country_name}</p>
              <p>Email: {userInfo.email_id}</p>
              <p>Phone#: {userInfo.phone_no}</p>
            </div>
          )}
          <h3>Cart Items</h3>
          {totalQuantity > 0 ? (
            <table className="cart-table">
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th>Price</th>
                  <th>Qty</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {cartItems.map((item, index) => (
                  <tr key={index}>
                    <td>{item.item_name}</td>
                    <td>Rs {item.item_price}</td>
                    <td>{item.quantity}</td>
                    <td>Rs {item.item_price * item.quantity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No items in the cart</p>
          )}
          <div className="total-amount">
            <strong>Total Amount: Rs {calculateTotalAmount()}</strong>
          </div>
          <div className="payment-section">
            <h3>Payment Options</h3>
            <select onChange={handlePaymentMethodChange} value={paymentMethod}>
              <option value="">Select Payment Method</option>
              <option value="cod">Cash on Delivery</option>
              <option value="card">Card Payment</option>
            </select>
            {paymentMethod === 'card' && (
              <div className="card-details">
                <div>
                  <label>
                    Card Number:
                    <input type="text" placeholder="Enter Card Number" />
                  </label>
                </div>
                <div>
                  <label>
                    Expiry Date:
                    <input type="text" placeholder="MM/YY" />
                  </label>
                </div>
                <div>
                  <label>
                    CVV:
                    <input type="text" placeholder="Enter CVV" />
                  </label>
                </div>
                <div>
                  <label>
                    Pin Code:
                    <input type="text" placeholder="Enter Pin Code" />
                  </label>
                </div>
              </div>
            )}
          </div>
          <div className="cart-actions">
            <button className="btn-color1" onClick={handleContinueShopping}>Continue Shopping</button>
            <button className="btn-color" onClick={handleConfirmOrder}>Confirm Order</button>
          </div>
        </>
      ) : (
        <div className="order-message" style={{ color: 'red' }}>
          <h2>Your order is received successfully.</h2>
          <p>Your tracking number is: {orderNo}</p>
        </div>
      )}
    </div>
  );
};

export default Confirm;
