import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './Signup.css';

function Signup() {
  const [emailId, setEmailId] = useState('');
  const [password, setPassword] = useState('');
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({ value: 'ae', label: 'United Arab Emirates' });
  const [typedCountryName, setTypedCountryName] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch country list from server using PHP API
    fetch('https://alibizness.com/shop/get_countries.php')
      .then(response => response.json())
      .then(data => {
        const countries = data.map(country => ({
          value: country.country_id,
          label: country.country_name
        }));
        setCountryOptions(countries);
      })
      .catch(error => {
        console.error('Error fetching countries:', error);
      });
  }, []);

  const handleCountryChange = selectedOption => {
    setSelectedCountry(selectedOption);
    setTypedCountryName(''); // Clear typed country name when country is selected
    setPhoneNo(''); // Reset phone number when country changes
  };

  const handlePhoneFocus = () => {
    // Clear phone number input on focus
    if (!phoneNo) {
      setPhoneNo('');
    }
  };

  const handleCountryInputChange = (e) => {
    const inputValue = e.target.value;
    setTypedCountryName(inputValue); // Update typed country name state
    // Find matching country from options based on typed input
    const selectedOption = countryOptions.find(option =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    setSelectedCountry(selectedOption || null); // Update selected country based on typed input
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = {
      email_id: emailId,
      passwords: password,
      country_id: selectedCountry ? selectedCountry.value : '',
      phone_no: phoneNo,
    };

    fetch('https://alibizness.com/shop/signup.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          navigate('/confirm');
        } else {
          console.error('Signup failed:', data.error);
        }
      })
      .catch(error => {
        console.error('There was an error submitting the form:', error);
      });
  };

  return (
    <div className="Signup">
      <h2>Signup Form</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Email:
          <input
            type="email"
            value={emailId}
            onChange={(e) => setEmailId(e.target.value)}
            required
            autoComplete="email"
            className="input-field"
            style={{ width: '100%', padding: '10px', margin: '5px 10px 10px -4px', border: '1px solid #ccc', borderRadius: '5px' }}
          />
        </label>
        <br />
        <label>
          Password:
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            autoComplete="new-password"
            className="input-field"
            style={{ width: '100%', padding: '10px', margin: '5px 10px 10px -4px', border: '1px solid #ccc', borderRadius: '5px' }}
          />
        </label>
        <br />
        <label>
          Country Name:
          <input
            type="text"
            value={typedCountryName}
            onChange={handleCountryInputChange}
            list="country-list"
            placeholder="Type to search country"
            autoComplete="off"
            className="input-field"
            style={{ width: '100%', padding: '10px', margin: '5px 10px 10px -4px', border: '1px solid #ccc', borderRadius: '5px' }}
          />
          <datalist id="country-list">
            {countryOptions.map(option => (
              <option key={option.value} value={option.label} />
            ))}
          </datalist>
        </label>
        <br />
        <label>
          Phone Number:
          <PhoneInput
            country={selectedCountry ? selectedCountry.value.toLowerCase() : ''}
            value={phoneNo}
            onChange={phone => setPhoneNo(phone)}
            onFocus={handlePhoneFocus}
            enableSearch
            searchPlaceholder="Search for a country"
            required
            inputStyle={{ width: '100%', padding: '10px', margin: '5px 10px 10px 15px', border: '1px solid #ccc', borderRadius: '5px' }}
          />
        </label>
        <br />
        <button type="submit" className="submit-button">Signup</button>
      </form>
      <p>
        Already have an account? <Link to="/login">Login here</Link>
      </p>
    </div>
  );
}

export default Signup;
