import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaShoppingCart, FaBars, FaTimes } from 'react-icons/fa';
import { useAuth } from './AuthContext';
import { CartContext } from './CartContext';
import './Header.css';

const Header = () => {
  const { user, logout } = useAuth();
  const { totalQuantity } = useContext(CartContext);
  const [categories, setCategories] = useState([]);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetch('https://alibizness.com/shop/get_category.php')
      .then(response => response.json())
      .then(data => setCategories(data))
      .catch(error => console.error('Error fetching categories:', error));
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <div className="header">
      <div className="logo-container">
        <Link to="/">
          <img src="https://alibizness.com/shop/lasma.png" alt="Logo" className="logo" />
        </Link>
      </div>
      <div className="left-menu-container">
        <button className="burger-menu-icon" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
        </button>
        <div className={`left-menu ${isMobileMenuOpen ? 'open' : ''}`}>
          <Link to="/" className="menu-item" onClick={toggleMobileMenu}>Home</Link>
          <Link to="/shop" className="menu-item" onClick={toggleMobileMenu}>Shop</Link>
          <Link to="/tracking" className="menu-item" onClick={toggleMobileMenu}>Tracking No</Link>
          {categories.map(category => (
            <Link
              to={`/category/${category.category_id}`}
              key={category.category_id}
              className="menu-item"
              onClick={toggleMobileMenu}
            >
              {category.category_name}
            </Link>
          ))}
        </div>
      </div>
      <div className="right-menu-container">
        {user ? (
          <button onClick={handleLogout} className="menu-item">Logout</button>
        ) : (
          <>
            <Link to="/login" className="menu-item">Login</Link>
            <Link to="/signup" className="menu-item">Signup</Link>
          </>
        )}
        <Link to="/cart" className="menu-item cart-icon-container">
          <FaShoppingCart size={24} />
          {totalQuantity > 0 && (
            <span className="cart-quantity">{totalQuantity}</span>
          )}
        </Link>
      </div>
    </div>
  );
};

export default Header;
