import React, { createContext, useState } from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);

  const addToCart = (product, quantity) => {
    const existingItem = cartItems.find((cartItem) => cartItem.item_id === product.item_id);

    if (existingItem) {
      setCartItems(
        cartItems.map((cartItem) =>
          cartItem.item_id === product.item_id
            ? { ...cartItem, quantity: cartItem.quantity + quantity }
            : cartItem
        )
      );
    } else {
      setCartItems([...cartItems, { ...product, quantity }]);
    }
  };

  const removeFromCart = (item_id) => {
    setCartItems(cartItems.filter((cartItem) => cartItem.item_id !== item_id));
  };

  const clearCart = () => {
    setCartItems([]);
  };

  const totalQuantity = cartItems.reduce((total, item) => total + item.quantity, 0);

  const calculateTotalAmount = () => {
    return cartItems.reduce((total, item) => total + item.item_price * item.quantity, 0);
  };

  return (
    <CartContext.Provider value={{ cartItems, addToCart, removeFromCart, clearCart, totalQuantity, calculateTotalAmount }}>
      {children}
    </CartContext.Provider>
  );
};
