import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; 
import './Categories.css';

const Categories = ({ onSelectCategory }) => {
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('https://alibizness.com/shop/get_category.php')
            .then(response => {
                setCategories(response.data);
            })
            .catch(error => {
                console.error('Error fetching categories: ', error);
            });
    }, []);

    const handleCategoryClick = (category) => {
        if (onSelectCategory) {
            onSelectCategory(category);
        }
        navigate(`/category/${category.category_id}`);
    };

    return (
        <div className="categories">
            {categories.map(category => (
                <div key={category.category_id} className="category" onClick={() => handleCategoryClick(category)}>
                    <img src={`https://alibizness.com/shop/uploads/${category.category_picture}`} alt={category.category_name} />
                    <p>{category.category_name}</p>
                </div>
            ))}
        </div>
    );
};

export default Categories;
