import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import ReactImageMagnify from 'react-image-magnify';
import Slider from 'react-slick';
import './ProductDetail.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CartContext } from './CartContext';

const ProductDetail = () => {
  const { itemId } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [gallery, setGallery] = useState([]);
  const [selectedImage, setSelectedImage] = useState('');
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [error, setError] = useState('');
  const { addToCart } = useContext(CartContext);

  useEffect(() => {
    fetchProduct();
    fetchGallery();
    fetchRelatedProducts();
  }, [itemId]);

  const fetchProduct = async () => {
    try {
      const response = await axios.get(`https://alibizness.com/shop/get_product.php?id=${itemId}`);
      setProduct(response.data);
      setSelectedImage(`https://alibizness.com/shop/uploads/${response.data.item_picture}`);
    } catch (err) {
      setError('Error fetching product.');
    }
  };

  const fetchGallery = async () => {
    try {
      const response = await axios.get(`https://alibizness.com/shop/get_gallery.php?item_id=${itemId}`);
      setGallery(response.data);
    } catch (err) {
      setError('Error fetching gallery.');
    }
  };

  const fetchRelatedProducts = async () => {
    try {
      const response = await axios.get(`https://alibizness.com/shop/get_latest_product.php`);
      setRelatedProducts(response.data);
    } catch (err) {
      setError('Error fetching related products.');
    }
  };

  const handleAddToCart = () => {
    const quantity = parseInt(document.querySelector('.quantity-input').value, 10);
    addToCart(product, quantity);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const handleRelatedProductClick = (id) => {
    navigate(`/productdetail/${id}`);
  };

  if (error) {
    return <p className="text-danger">{error}</p>;
  }

  if (!product) {
    return <p>Loading...</p>;
  }

  return (
    <div className="product-detail-container">
      <hr />
      <h2 className="product-detail-title">Product Detail</h2>
      <div className="product-detail-content">
        <div className="product-images">
          <ReactImageMagnify
            {...{
              smallImage: {
                alt: product.item_name,
                isFluidWidth: true,
                src: selectedImage,
              },
              largeImage: {
                src: selectedImage,
                width: 1000,
                height: 1500,
              },
              enlargedImageContainerDimensions: {
                width: '150%',
                height: '150%',
              },
            }}
          />
          <div className="gallery-images">
            {gallery && gallery.length > 0 ? (
              gallery.map((image, index) => (
                <img
                  key={index}
                  src={`https://alibizness.com/shop/uploads/${image.item_picture}`}
                  alt={`Gallery ${index}`}
                  className={`gallery-image ${selectedImage === `https://alibizness.com/shop/uploads/${image.item_picture}` ? 'selected' : ''}`}
                  onClick={() => setSelectedImage(`https://alibizness.com/shop/uploads/${image.item_picture}`)}
                />
              ))
            ) : (
              <p>No gallery images available</p>
            )}
          </div>
        </div>
        <div className="product-info">
          <h2>{product.item_name}</h2>
          <p>Rs {product.item_price}</p>
          <div dangerouslySetInnerHTML={{ __html: product.description }} />
          <div className="add-to-cart">
            <input type="number" defaultValue={1} min={1} className="quantity-input" />
            <button className="add-to-cart-button" onClick={handleAddToCart}>Add to Cart</button>
          </div>
        </div>
      </div>
      <hr />
      <h2 className="related-products-title">Related Products</h2>
      <Slider {...settings}>
        {relatedProducts.map((relatedProduct) => (
          <div key={relatedProduct.item_id} className="related-product" onClick={() => handleRelatedProductClick(relatedProduct.item_id)}>
            <img
              src={`https://alibizness.com/shop/uploads/${relatedProduct.item_picture}`}
              alt={relatedProduct.item_name}
              className="related-product-image"
            />
            <p className="related-product-name">{relatedProduct.item_name}</p>
            <p className="related-product-price">Rs {relatedProduct.item_price}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ProductDetail;
