import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { CartContext } from './CartContext';
import './Category.css';

const Category = () => {
  const { category_id } = useParams();
  const [categoryName, setCategoryName] = useState('');
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]); // Ensure this is initialized
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState('');
  const { addToCart } = useContext(CartContext);

  useEffect(() => {
    fetchCategoryItems();
  }, [category_id]);

  const fetchCategoryItems = async () => {
    try {
      const response = await axios.get(`https://alibizness.com/shop/get_category_items.php?category_id=${category_id}`);
      setItems(response.data.items);
      setFilteredItems(response.data.items); // Initialize filteredItems with the fetched items
      setCategoryName(response.data.category_name);
    } catch (err) {
      setError('Error fetching category items.');
    }
  };

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term === '') {
      setFilteredItems(items);
    } else {
      const filtered = items.filter(item => 
        item.item_name.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredItems(filtered);
    }
  };

  return (
    <div className="category-container">
      <h2>{categoryName} Items</h2>
      {error && <p className="text-danger">{error}</p>}
      <input 
        type="text"
        placeholder="Search items..."
        value={searchTerm}
        onChange={handleSearch}
        className="search-bar"
      />
      <div className="items-container">
        {filteredItems.map(item => (
          <div key={item.item_id} className="item-card">
            <img src={`https://alibizness.com/shop/uploads/${item.item_picture}`} alt={item.item_name} className="product-image" />
            <div className="item-details">
              <h3 className="item-name">{item.item_name}</h3>
              <p className="item-price">Rs{item.item_price}</p>
              <button onClick={() => addToCart(item)} className="btn btn-primary add-to-cart-button">Add to Cart</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Category;
