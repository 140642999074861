import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './Header';
import ImageSlider from './Slider';
import Latest from './Latest';
import Login from './Login';
import Signup from './Signup';
import CartIcon from './CartIcon';
import Confirm from './Confirm';
import Shop from './Shop';
import Category from './Category';
import Categories from './Categories';
import { AuthProvider } from './AuthContext'; // Import AuthProvider
import { CartProvider } from './CartContext'; // Import CartProvider
import ProductDetail from './ProductDetail';
import Tracking from './Tracking';
import Chat from './Chat';

function App() {
  // Define handleSelectProduct function
  const handleSelectProduct = (itemId) => {
    // Implement navigation logic here
    console.log(`Selected product with ID: ${itemId}`);
  };

  return (
    <AuthProvider> {/* Ensure AuthProvider wraps your entire application */}
      <CartProvider> {/* Ensure CartProvider wraps your entire application */}
        <Router>
          <div className="app-container">
            <Header />
            <div className="main-content">
              <Routes>
                <Route path="/" element={
                  <>
                    <ImageSlider />
                    <Categories />
                    <Latest />
                    <Chat />
                  </>
                } />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/cart" element={<CartIcon />} />
                <Route path="/confirm" element={<Confirm />} />
                {/* Pass handleSelectProduct as a prop to Shop */}
                <Route path="/shop" element={<Shop onSelectProduct={handleSelectProduct} />} />
                <Route path="/category/:category_id" element={<Category />} />
                <Route path="/productdetail/:itemId" element={<ProductDetail />} />
                <Route path="/tracking" element={<Tracking />} />
              </Routes>
            </div>
          </div>
        </Router>
      </CartProvider>
    </AuthProvider>
  );
}

export default App;
