import React, { useContext } from 'react';
import { CartContext } from './CartContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import './Confirm.css';

const CartIcon = () => {
  const { cartItems, removeItem, calculateTotalAmount } = useContext(CartContext);

  const handleDeleteItem = (itemId, itemName) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete ${itemName}?`);
    if (confirmDelete) {
      removeItem(itemId);
    }
  };

  return (
    <div className="cart-page">
      <h3>Cart Items</h3>
      {cartItems.length > 0 ? (
        <>
          <table className="cart-table">
            <thead>
              <tr>
                <th>Item Name</th>
                <th>Price</th>
                <th>Qty</th>
                <th>Amount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {cartItems.map((item, index) => (
                <tr key={index}>
                  <td>{item.item_name}</td>
                  <td>Rs {item.item_price}</td>
                  <td>{item.quantity}</td>
                  <td>Rs {item.item_price * item.quantity}</td>
                  <td>
                    <button className="btn-color" onClick={() => handleDeleteItem(item.item_id, item.item_name)}>
                      <FontAwesomeIcon icon={faTrashAlt} className="delete-icon" />
                      <span>Delete</span>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="total-amount">
            <strong>Total Amount: Rs {calculateTotalAmount()}</strong>
          </div>
        </>
      ) : (
        <p>No items in the cart</p>
      )}
    </div>
  );
};

export default CartIcon;
